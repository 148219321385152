import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import { ChampionsCard, Layout, Title, Card, CardContainer, EducateContent} from '../AdventureCategories/styles';
import { NoComments } from '../SocialFeeds/styles';
import ThumbnailNew from '../ThumbnailNew';
import RecommendedVideos from '../RecommendedVideos/recommendedVideo';
import { Button } from './styles'
import { ImageUrl } from '../../utils/constants';
import { withTranslation } from 'react-i18next';
import AdventurePointDashBoard from '../AdventurePointDashBoard';
import {AdminButtons} from '../Article/styles'

class VideoLibraryDashboard extends React.Component {
  constructor() {
    super();
    this.state = {
      onHover: -1,
      temp: 0,
      tempNew: 0
    }
  }
  componentDidMount() {
    const { link, videoLibrariesDetails, history } = this.props;
    if (link === 'webinar' && videoLibrariesDetails?.length === 1) {
      history.push(`/education/webinar/${videoLibrariesDetails[0]?.category_name}`)
    }
  }

  onEnterMouse = (id) => {
    this.setState({
      onHover: id
    })
  };

  onLeaveMouse = () => {
    this.setState({
      onHover: -1
    })
  };

  changeVideosDashboard = (categoryName) => {
    const { history, link } = this.props;
    history.push(`/education/${link}/${categoryName.toLowerCase()}`);
  };

  seeMore = () => {
    this.setState({
      temp: !this.state.temp,
      tempNew: !this.state.tempNew
    })
  }

  getVideoCategories = (videoLibrariesDetails) => (
    <div>
      <div style={{ "display": "flex", "flex-wrap": "wrap", "background": "white", "padding": "2px 2px 0px 2px", "border-radius": "6px" }}>
        {videoLibrariesDetails?.map((video, index) => (
          index < 10 ?
            <ChampionsCard key={index} grid={5}>
              <Card adventurePage onClick={() => this.props.history.push(`/education/videos-library/category/${video['category_name'].toLowerCase()}`)} border={((index === 0) || (index % 5 === 0)) ? 1 : 0}>
                <div className="video-category" onMouseEnter={() => this.onEnterMouse(video.id)} onMouseLeave={() => this.onLeaveMouse()}>
                  <ThumbnailNew
                    src={`${ImageUrl}/${video.image}`}
                    alt={video.category_name}
                  />
                  <div className='content'>
                    <EducateContent>
                      <div className='work'>
                        <div>
                          {this.props.t(video.category_name)}
                        </div>
                      </div>
                    </EducateContent>
                  </div>
                </div>
              </Card>
            </ChampionsCard> : null
        ))}
        {videoLibrariesDetails?.map((video, index) => (
          index >= 10 && this.state.temp ?
            <ChampionsCard key={index} grid={5}>
              <Card adventurePage onClick={() => this.props.history.push(`/education/videos-library/category/${video['category_name'].toLowerCase()}`)} border={((index === 0) || (index % 5 === 0)) ? 1 : 0}>
                <div className="video-category" onMouseEnter={() => this.onEnterMouse(video.id)} onMouseLeave={() => this.onLeaveMouse()}>
                  <ThumbnailNew
                    src={`${ImageUrl}/${video.image}`}
                    alt={video.category_name}
                  />
                </div>
                <div className='content'>
                  <EducateContent>
                    <div className='work'>
                      <div>
                        {this.props.t(video.category_name)}
                      </div>
                    </div>
                  </EducateContent>
                  <div className='description'>
                    {this.props.t(video.description)}
                  </div>
                </div>
              </Card>
            </ChampionsCard> : null
        ))}
      </div>
      <div style={{ "width": "100%", "margin-top": "0px", "background": "rgba(156,156,156,0.1)", "border-radius": "6px" }}>
        {this.state.temp ?
          <Button onClick={() => this.seeMore()}>{this.props.t("See Less")}   <img src="/public/images/NewDashboardV2/downarrownew.png" style={{ "transform": "rotate(180deg)" }} /></Button> :
          <Button onClick={() => this.seeMore()}>{this.props.t("See More")}   <img src="/public/images/NewDashboardV2/downarrownew.png" /></Button>
        }
      </div>
    </div>

  )

  render() {
    const { videoLibrariesDetails, featuredVideos, history, link, favoriteVideos, t, onHover, onClickFavoriteRibbon,createVideo, createCategory } = this.props;
    let featuredVideosData = featuredVideos && featuredVideos.length >0 && featuredVideos.slice(0,4);
    return (
      <Layout>
        <div>
          <div style={{ "display": "flex" }}>
            <div style={{ "width": "67.1%" }}>
              <CardContainer >
                {isEmpty(videoLibrariesDetails) ? <NoComments>{t("No Fitness Videos")}</NoComments> : this.getVideoCategories(videoLibrariesDetails)}
              </CardContainer>
              {link === "videos-library" &&
                <div>
                  <Title bottom={'15px'}>{t("Featured Fitness Videos")}</Title>
                  <CardContainer>
                    {isEmpty(featuredVideosData) ? <NoComments>{t("No Recommended Fitness Videos")}</NoComments> :
                      featuredVideosData && featuredVideosData.length > 0 && featuredVideosData.map((video, index) => (
                        <RecommendedVideos onClickFavoriteRibbon={onClickFavoriteRibbon} video={video} key={index} onHover={onHover} history={history} grid={0} link={link} margin={index % 2 === 1 ? "0px" : '0px'} bottom={"25px"} width={'calc((100% / 2) - 12.5px)'} />
                      ))
                    }
                  </CardContainer>
                  <Title style={{ "margin-bottom": "15px" }}>{t("Favorite Fitness Videos")}</Title>
                  <CardContainer>
                    {isEmpty(favoriteVideos) ? <NoComments /> :
                      favoriteVideos && favoriteVideos.length > 0 && favoriteVideos.map((video, index) => (
                        <RecommendedVideos onClickFavoriteRibbon={onClickFavoriteRibbon} video={video} key={index} onHover={onHover} history={history} grid={0} link={link} margin={index % 2 === 1 ? "0px" : '0px'} bottom={"25px"} width={'calc((100% / 2) - 12.5px)'} />
                      ))
                    }
                  </CardContainer>
                </div>
              }
            </div>
            <div style={{ "width": "32.9%", "padding-left": "25px", "position": "relative", "bottom": "55px" }}>
              { createVideo &&
                <AdminButtons background={'#FD7175'} onClick={() => history.push('/education/create-video')}>
                  <div>
                    <img src="/public/images/NewDashboardV2/createvideo.png" alt="editvideo" />
                  </div>
                  <div>
                    {t("Create Fitness Video")}
                  </div>
                </AdminButtons>
              }
              { createCategory &&
                <AdminButtons background={'#69C2FF'} onClick={() => history.push({pathname:'/education/create-video-category',state:{categoryKey:"FITNESS"}})}>
                  <div>
                    <img src="/public/images/NewDashboardV2/createjourney.png" alt="editvideo" />
                  </div>
                  <div>
                    {t("Create Fitness Category")}
                  </div>
                </AdminButtons>
              }
              <AdventurePointDashBoard />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

VideoLibraryDashboard.propTypes = {
  videoLibrariesDetails: PropTypes.array,
  history: PropTypes.object.isRequired,
  featuredVideos: PropTypes.array,
  link: PropTypes.string,
  categoryTitle: PropTypes.string,
  createVideo: PropTypes.bool,
  createCategory: PropTypes.bool,
  t: PropTypes.func,
  margin: PropTypes.string,
  bottom: PropTypes.string,
  onHover: PropTypes.string,
  favoriteVideos: PropTypes.array,
  onClickFavoriteRibbon: PropTypes.func
};

export default (withTranslation()(VideoLibraryDashboard));
